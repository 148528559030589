import React from "react"
import { Link } from "gatsby"
import { FaSitemap } from "react-icons/fa"

import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import NavBar from "../components/NavBar/NavBar"
import Search from "../components/SearchInput/Search"
import CardBox from "../componentForPages/HomePageComponents/Card"

import { Title, Columns, Column } from "../StyleComponents/styles"
import { HomeBoxList } from "../StyleComponents/homePageStyle"
import { isSSR } from "../components/Script/script"

const SeeAlso = React.lazy(() => import("../components/SeeAlso/SeeAlso"))
const GoogleAds = React.lazy(() => import("../components/ADS/ADS"))

const seeAlsoArray = [
  "/emoticons-copy-and-paste",
  "/font-generator",
  "/emoji-copy-and-paste",
  "/image-to-text",
]

const newUpdatedArray = [
  "/wheel-of-names",
  "/morse-code-conversion",
  "/reverse-percentages-calculator",
]

export default props => {
  return (
    <>
      <SEO
        title="Unit Converter, Calculator, Name Generators And Tools"
        description="UnitPedia is an online web page for unit converter, math, and other stuff calculators, extra tools, name generator, and other tools generators."
        keywords={[
          "unit converter, name generator, calculator, math calculator, calculate volume, calculate area, ip address, emoticons, emotes, emoji, ascii, morse code, tools, online tools, quote generator, memes maker, font generator",
        ]}
      />
      <Layout location={props.location}>
        <NavBar />
        <div className="container">
          <Title textAlign="center">
            <FaSitemap /> UnitPedia.com
          </Title>
          <br />
          <Search />
          <br />
          <Columns>
            <Column>
              <HomeBoxList className="mb-3">
                <CardBox />
              </HomeBoxList>
              <br />
              {!isSSR && (
                <React.Suspense fallback={<div />}>
                  <SeeAlso
                    title="New/updated pages"
                    seeAlsoList={newUpdatedArray}
                  />
                </React.Suspense>
              )}
              <br />
              {!isSSR && (
                <React.Suspense fallback={<div />}>
                  <SeeAlso title="Popular pages" seeAlsoList={seeAlsoArray} />
                </React.Suspense>
              )}
            </Column>
            <Column ChildMargin="auto">
              {!isSSR && (
                <React.Suspense fallback={<div />}>
                  <GoogleAds
                    slot="1189740279"
                    data-ad-format="auto"
                    data-ad-layout-key="-gi+g+7v-1q-6r"
                    data-full-width-responsive="true"
                  />
                </React.Suspense>
              )}
            </Column>
          </Columns>
          <br />
          <p>
            Unitpedia.com is funded by personalized ads.
            <br /> For more information visit our{" "}
            <Link to="/privacy/">Privacy Policy</Link>.
          </p>
        </div>
      </Layout>
    </>
  )
}
