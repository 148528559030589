import React from "react"
import { Link } from "gatsby"
import { FixedSizeList as List } from "react-window"

function DisplayList({list}) {
  const Row = ({ index, style }) => {
    let parts = list[index].split("/")
    return (
      <Link to={`${list[index]}/`} style={style}>
      {parts[parts.length - 1].split("-").join(" ")}
      </Link>
    )
  }
  return (
    <>
      <List
        height={300}
        itemCount={list.length}
        itemSize={30}
        width={"100%"}
      >
        {Row}
      </List>
    </>
  )
}

export default DisplayList
