import styled from "styled-components"

export const HomeBoxList = styled.div`
  & .listGroup {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    & div {
      outline: none;
    }
    & h3 {
      margin: 0;
      outline: none;
      &.item{
        background-color: #f2f2f2;
        &:hover:not(.activeA){
          background-color: #e5e5e5;
        }
      }
    }
    
    & .items {
      width: 100%;
      color: #495057;
      text-align: inherit;
      &.subTitleC{
      background-color: #8fd0f9;
      color:white
      }
      &:hover:not(.subTitleC, .activeA),
      :focus {
        z-index: 1;
        color: #495057;
        text-decoration: none;
        background-color: #f8f9fa;
      }
      &.activeA {
        z-index: 2;
        color: #fff;
        background-color: #1da1f2;
        border-color: #1da1f2;
      }
    }
    & .item {
      position: relative;
      display: block;
      padding: 0.75rem 1.25rem;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.125);
      & + .item {
        border-top-width: 0;
      }
    }
    & + .listGroup h3.item{
        margin-top: -1px;
        border-top-width: 1px;
      }
    }
  }
  & .listGroup:first-child h3.item{
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  & .listGroup:last-child h3.item{
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    &.activeA{
      border-radius:0;
    }
  }


`
