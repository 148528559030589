import React, { useState } from "react"
import {
  Button,
  Input,
  Field,
  Control,
  LoadingAnimation,IconButton,BoxShadowWindow,
} from "../../StyleComponents/styles"
import { FlexDiv } from "../../StyleComponents/pagesStyle"

import DisplayList from "./components/ScrollWindow"

const Search = () => {
  let [valueSearch, setvalueSearch] = useState("")
  let [resultTitle, setResultTitle] = useState("")
  let [loading, setloading] = useState(false)
  let [show, setShow] = useState(false)

  const emptyQuery = ""
  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  })
  const finnalResult = e => {
    e.preventDefault()
    if (valueSearch === "") {
      setShow(false)
    } else {
      setloading(!loading)
      const query = valueSearch
      const queryToLower = query.toLowerCase()
      const arrayQuery = queryToLower.split(" ")

      import("./components/allPagesUrl")
        .then(module => {
          const posts = module.default || []
          const filteredData = posts.filter(post => {
            let result = arrayQuery.every(item => post.includes(item))
            return result
          })
          setState({
            query,
            filteredData,
          })
          setShow(true)
          if (filteredData.length === 0) {
            setResultTitle("No Search Results Found")
          } else {
            setResultTitle("Search Result")
          }
        })
        .catch(error => console.log(error))
        .finally(() => setloading(false))
    }
  }
  const { filteredData } = state
  return (
    <FlexDiv maxWidth="500px" margin="auto">
      <form className="mb-3" onSubmit={finnalResult} >
        <Field>
          <Control className="expandedWidth">
            <Input
              className="borderedLeft"
              aria-label="searchPages"
              type="text"
              value={valueSearch}
              onChange={e => setvalueSearch(e.target.value)}
              disabled={loading ? true : ""}
            />
          </Control>
          <Control>
            <Button
              borderColor="#1da1f2"
              color="white"
              hoverColor="white"
              hoverBorderColor="#1da1f2"
              hoverBackgroundColor="#0094f0"
              backgroundColor="#1da1f2"
              disbledBackgoundColor="#1da1f2"
              disabledColor="white"
              className="staticButton"
              aria-label="searchForPages"
              disabled={loading ? true : ""}
            >
              Search{" "}
              {loading ? (
                <LoadingAnimation
                  width="10px"
                  height="10px"
                  padding="9px"
                  border="2px solid white"
                />
              ) : (
                ""
              )}
            </Button>
          </Control>
        </Field>
      </form>
      <div>
        {loading ? (
          <LoadingAnimation />
        ) : show ? (
          <BoxShadowWindow>
            <p className="h5">
              <span>{resultTitle}</span>
              <span style={{ float: "right" }}>
                <IconButton onClick={() => setShow(false)}>&#10008;</IconButton>
              </span>
            </p>
            <hr/>
              <DisplayList list={filteredData} />
          </BoxShadowWindow>
        ) : (
          ""
        )}
      </div>
    </FlexDiv>
  )
}

export default Search
