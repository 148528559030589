import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import DisplayList from "./ScrollWindow"
import { isSSR } from "../../components/Script/script"
import { AngelRotation, ArrowDown } from "../../StyleComponents/styles"

const CalculatorBox = React.lazy(() => import("./Calculator"))
const ToolsBox = React.lazy(() => import("./Tools"))
const GeneratorBox = React.lazy(() => import("./Generator"))

const CardBox = () => {
  let [open, setOpen] = useState(true)
  const data = useStaticQuery(graphql`
    query {
      allConverterJson {
        edges {
          node {
            title
            type {
              title
              pathAndName {
                name
                link
              }
            }
          }
        }
      }
    }
  `)
  const ConverterList = data.allConverterJson.edges[0].node
  return (
    <>
      {ConverterList.type.map((element, index) => {
        return (
          <div className="listGroup" key={index}>
            <div
              tabIndex="0"
              role="button"
              onClick={() => setOpen(!open)}
              onKeyDown={() => setOpen(!open)}
            >
              <h3 className={`item items ${open ? "activeA" : ""}`}>
                {element.title}{" "}
                <AngelRotation
                  transform={open ? "rotate(180deg)" : ""}
                  aria-hidden="true"
                >
                  <ArrowDown bordervColor={open ? "white" : ""} />
                </AngelRotation>
              </h3>
            </div>

            {open ? <DisplayList list={element.pathAndName} /> : ""}
          </div>
        )
      })}

      {!isSSR && (
        <React.Suspense
          fallback={
            <>
              <div className="listGroup">
                <h3 className="item items">
                  Calculator
                  <AngelRotation aria-hidden="true">
                    <ArrowDown />
                  </AngelRotation>
                </h3>
              </div>
              <div className="listGroup">
                <h3 className="item items">
                  Utility Tools
                  <AngelRotation aria-hidden="true">
                    <ArrowDown />
                  </AngelRotation>
                </h3>
              </div>
              <div className="listGroup">
                <h3 className="item items">
                  Random Generator
                  <AngelRotation aria-hidden="true">
                    <ArrowDown />
                  </AngelRotation>
                </h3>
              </div>
            </>
          }
        >
          <CalculatorBox />
          <ToolsBox />
          <GeneratorBox />
        </React.Suspense>
      )}
    </>
  )
}

export default CardBox
