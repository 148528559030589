import React from "react"
import { Link } from "gatsby"
import { FixedSizeList as List } from "react-window"

function DisplayList({ list }) {
  const Row = ({ index, style }) => {
    if (list[index].subTitle) {
      return (
        <p className="item items subTitleC h4" style={style}>
          {list[index].subTitle}
        </p>
      )
    } else {
      return (
        <Link className="item items" to={`/${list[index].link}/`} style={style}>
          {list[index].name}
        </Link>
      )
    }
  }
  return (
    <>
      <List height={300} itemCount={list.length} itemSize={55} width={"100%"}>
        {Row}
      </List>
    </>
  )
}

export default DisplayList
